<template>
  <div class="illustration-page main">
    <div class="bg-black">
      <div class="ss-container">
        <h6 class="section-title">Illustration</h6>
        <p class="section-desc">一些小插畫</p>
      </div>
    </div>
    <div class="bg-black black-section">
      <ListTemplate
        :data="bgBlackIllustrationsSmall"
        category="illustration"
      ></ListTemplate>
      <ListTemplate
        :data="bgBlackIllustrationsLarge"
        category="illustration"
      ></ListTemplate>
    </div>
    <div class="bg-white white-section">
      <ListTemplate
        :data="bgWhiteIllustrationsSmall"
        category="illustration"
      ></ListTemplate>
      <ListTemplate
        :data="bgWhiteIllustrationsLarge"
        category="illustration"
      ></ListTemplate>
    </div>
  </div>
</template>

<script>
import ListTemplate from "@/components/ListTemplate.vue";
import Illustrations from "@/data/illustrations.js";
export default {
  name: "Diary",
  components: {
    ListTemplate
  },
  data() {
    return {
      illustrations: Illustrations
    };
  },
  computed: {
    bgBlackIllustrationsSmall() {
      return this.illustrations.filter(function(item) {
        return item.type === 1 && item.size === 1;
      });
    },
    bgBlackIllustrationsLarge() {
      return this.illustrations.filter(function(item) {
        return item.type === 1 && item.size === 2;
      });
    },
    bgWhiteIllustrationsSmall() {
      return this.illustrations.filter(function(item) {
        return item.type === 2 && item.size === 1;
      });
    },
    bgWhiteIllustrationsLarge() {
      return this.illustrations.filter(function(item) {
        return item.type === 2 && item.size === 2;
      });
    }
  }
};
</script>

<style lang="sass" scoped>
.illustration-page
  padding-top: 130px
  background-color: #111111
  +deviceWidth()
    padding-top: 50px
.black-section
  padding-bottom: 48px
  +deviceWidth()
    padding-bottom: 30px
.white-section
  padding-top: 140px
  padding-bottom: 48px
  +deviceWidth()
    padding-top: 50px
    padding-bottom: 30px
</style>
